
import bgmi1 from '../assets/images/bgmi1.jpeg'
import bgmi2 from '../assets/images/bgmi2.jpeg'
import genshinImg1 from '../assets/images/genshin-impact1.jpeg'
import genshinImg2 from '../assets/images/genshin-impact2.jpeg'
import mlbbImg1 from '../assets/images/mobile-legend.jpeg'
import mlbbImg2 from '../assets/images/mobile-legend2.jpeg'
import metalSlugImg1 from '../assets/images/metal_slug1.jpeg'
import metalSlugImg2 from '../assets/images/metal_slug2.jpeg'
import freeFireImg1 from '../assets/images/free-fire.jpeg'
import codImg1 from '../assets/images/call-of-duty1.jpeg'
import codImg2 from '../assets/images/call-of-duty-call2.jpeg'
import coinsImg1 from '../assets/images/coins1.jpeg'
import coinsImg2 from '../assets/images/coins2.jpeg'

const itemData = [
    {
      id : 0,
      title: "PUBG MOBILE",
      imgSrc: bgmi1,
      imgSrc2: bgmi2,
      description:
        "Recharge your BGmi account with ease! Simply enter your BGmi username, choose the amount of BGmi Points you want to add, complete the payment, and watch as your BGmi Points are instantly credited to your account. Enjoy the convenience of UPI payments without the need for any registration or login!",
      instructions: [
        "To find your UID, open BGmi, click the settings button, and click the copy button on the right of your UID.",
        "Please fill in your email address if you want proof of payment.",
      ],
      pricingOptions: [
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "50 BP",
          startingPriceFrom: "From",
          startingPriceValue: "₹34.00",
        },
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_Benefits7days_1330gold.png",
          label: "500 BP",
          startingPriceFrom: "From",
          startingPriceValue: "₹134.00",
        },
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "5000 BP",
          startingPriceFrom: "From",
          startingPriceValue: "₹234.00",
        },
        // Add more pricing options here if needed
      ],
    },
    {
      id: 1,
      title: "Genshin Impact",
      imgSrc: genshinImg1,
      imgSrc2: genshinImg2,
      description:
        "Embark on an epic journey in the world of Teyvat. Genshin Impact is an action RPG where you'll explore a magical open world, unlock characters, and engage in thrilling battles. Recharge your Genesis Crystals and Primogems effortlessly with UPI payments.",
      instructions: [
        "To find your UID, open Genshin Impact, go to the settings menu, and find your UID in the Account section.",
        "Please provide your email for payment confirmation.",
      ],
      pricingOptions: [
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "Genesis Crystals (100)",
          startingPriceFrom: "From",
          startingPriceValue: "₹79.00",
        },
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "Primogems (3000)",
          startingPriceFrom: "From",
          startingPriceValue: "₹499.00",
        },
        // Add more pricing options here if needed
      ]
    },
    {
      id: 2,
      title: "Mobile Legends: Bang Bang",
      imgSrc: mlbbImg1,
      imgSrc2: mlbbImg2,
      description:
        "Join the battle in Mobile Legends: Bang Bang! Choose your heroes, team up with friends, and fight against opponents in fast-paced 5v5 battles. Top up your Diamonds for in-game purchases using UPI payments.",
      instructions: [
        "To find your Player ID, open Mobile Legends, tap on your profile picture, and locate your Player ID.",
        "For payment receipts, please provide your email address during checkout.",
      ],
      pricingOptions: [
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "Diamonds (50)",
          startingPriceFrom: "From",
          startingPriceValue: "₹40.00",
        },
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "Diamonds (500)",
          startingPriceFrom: "From",
          startingPriceValue: "₹399.00",
        },
        // Add more pricing options here if needed
      ]
    },
    {
      id: 3,
      title: "Metal Slug",
      imgSrc: metalSlugImg1,
      imgSrc2: metalSlugImg2,
      description:
        "Experience the classic run-and-gun action of Metal Slug! Fight against hordes of enemies and epic bosses. Purchase in-game items and power-ups by recharging your Coins and Medals through UPI payments.",
      instructions: [
        "To find your Player ID, open Metal Slug, navigate to the settings, and locate your Player ID in the Account section.",
        "If you require payment confirmation, please enter your email address.",
      ],
      pricingOptions: [
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "Coins (1000)",
          startingPriceFrom: "From",
          startingPriceValue: "₹49.00",
        },
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "Medals (50)",
          startingPriceFrom: "From",
          startingPriceValue: "₹29.00",
        },
        // Add more pricing options here if needed
      ]
    },
    {
      id: 4,
      title: "Free Fire",
      imgSrc: freeFireImg1,
      imgSrc2: freeFireImg1,
      description:
        "Survival is key in Free Fire! Play the ultimate battle royale game, equip yourself with weapons, and be the last person standing. Recharge your Diamonds to unlock premium content and character skins using UPI payments.",
      instructions: [
        "To find your Player ID, open Free Fire, go to your profile, and find your Player ID under your username.",
        "For payment receipts, please provide your email address when making a purchase.",
      ],
      pricingOptions: [
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "Diamonds (100)",
          startingPriceFrom: "From",
          startingPriceValue: "₹80.00",
        },
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "Diamonds (500)",
          startingPriceFrom: "From",
          startingPriceValue: "₹399.00",
        },
        // Add more pricing options here if needed
      ]
    },
    {
      id: 5,
      title: "Call of Duty Mobile",
      imgSrc: codImg1,
      imgSrc2: codImg2,
      description:
        "Engage in intense first-person shooter action in Call of Duty Mobile. Customize your loadouts and join multiplayer battles. Recharge your COD Points for in-game purchases with UPI payments.",
      instructions: [
        "To find your UID, open Call of Duty Mobile, go to the settings menu, and find your UID under the Account section.",
        "For payment confirmations, please enter your email address during the payment process.",
      ],
      pricingOptions: [
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "COD Points (1000)",
          startingPriceFrom: "From",
          startingPriceValue: "₹79.00",
        },
        {
          img : "https://cdn1.codashop.com/S/content/common/images/denom-image/MSO/MSO_4200_gold.png",
          label: "COD Points (5000)",
          startingPriceFrom: "From",
          startingPriceValue: "₹399.00",
        },
        // Add more pricing options here if needed
      ]
    }
    // Add more objects for additional games/items here
  ];
  
  export default itemData;