import React from 'react';
import './item.css?v=1.0';
import itemData from '../../utils/itemData';
import { useParams } from 'react-router';
import {useState} from "react";

const Item = () => {
  const { id } = useParams();
const item = itemData[id];
  const [selectedOption, setSelectedOption] = useState(null);

  // Function to handle the button click and update the selected option
  const handleButtonClick = (index) => {
    setSelectedOption(index);
  };


  return (
    <div className="page-container">
      <section className='item section__padding'>
        <div className="item-content">
          <div className="item-image">
            <img src={item.imgSrc2} alt="item" className="item-image" />
          </div>
          <div className="item-content-title">
            <h1>{item.title}</h1>
          </div>
          <div className="item-content-buy">
            <button className="primary-btn">Official Distributor</button>
            {/* <button className="secondary-btn"> Secure Payment</button> */}
          </div>
          <div className="item-content-detail">
            <p>
            {item.description}
            </p>
          </div>
        </div>
      </section>
      <section className="pricing-content">
        <section className="pricing-content1">
        <h2 className="form-section__circle">
          <span className="form-section__number">1</span>
        </h2>
        <div className="form-section__server-info">
          {/* Replace this div with your input field */}
          <div className="gameUserInput form-section__gameUserInput">
              <span className="form-section__name">Enter UID</span>
                <div className="touched pristine invalid validated required failed">
                  <div className="input-block">
                    <label for="userId" className="form-input--label"></label>
                      <input
                        placeholder="Enter UID"
                        type="tel"
                        id="userId"
                        name="user_0"
                        model=""
                        autoComplete="off"
                        className="userid form-input font-normal text-center"
                      />
              </div>
              <span className="input-field--error">*This field is required</span>
            </div>
          </div>
          <p className="form-section__instruction">
           {item.instructions}
          </p>
        </div>
        </section>
          <section className="pricing-content2">
            <h2 className="form-section__circle">
              <span className="form-section__number">2</span>
            </h2>
            <span className="form-section__name">Select Recharge</span>
            <ul className="form-section__denom-group">
              {/* Repeat this structure for each pricing option */}
              {item.pricingOptions.map((option, index) => (
                <li className="form-section__denom no-category" key={index}>
                  <div className="form-section__denom-btn form-section__with-image">
                    <div
                        className={`${
                            selectedOption === index ? 'highlighted' : ''
                        } form-section_denom-inner `} onClick={() => handleButtonClick(index)}
                    >
                      <div className="form-section__denom-data-section">
                      <img src={option.img} alt="item" className="coins-image" />
                      <div className='pricing-details'>
                        <span>{option.label}</span>
                        <span className="starting-price-from">{option.startingPriceFrom}</span>
                        <span className="starting-price-value">{option.startingPriceValue}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </section>
        <section className="pricing-content1">
        <h2 className="form-section__circle">
          <span className="form-section__number">3</span>
        </h2>
        <div className="form-section__server-info">
          {/* Replace this div with your input field */}
          <div className="gameUserInput form-section__gameUserInput">
              <span className="form-section__name">Enter Email (optional)</span>
                <div className="touched pristine invalid validated required failed">
                  <div className="input-block">
                    <label for="userId" className="form-input--label"></label>
                      <input 
                        placeholder="Enter Email"
                        type="tel"
                        id="userId"
                        name="user_0"
                        model=""
                        autoComplete="off"
                        className="userid form-input font-normal text-center"
                      />        
              </div>
            </div>
          </div>
            <p className="form-section__instruction">
          Please fill in email address if you want proof of payment
          </p>
        </div>
        </section>
      </section>
    </div>
  );
};

export default Item;
