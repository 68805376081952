import React from 'react';
import {Bids, Header, } from '../../components'


const Home = () => {

  return <div>
   <Header />
   <Bids title="Trending Games" loadMore={false}/>
   <Bids title="Direct Top Up" loadMore={true}/>
  </div>;
};

export default Home;
