import bgmi1 from '../assets/images/bgmi1.jpeg'
import bgmi2 from '../assets/images/bgmi2.jpeg'
import genshinImg2 from '../assets/images/genshin-impact2.jpeg'
import codImg2 from '../assets/images/call-of-duty-call2.jpeg'
import mlbbImg2 from '../assets/images/mobile-legend2.jpeg'
export const Promotions = [
    {
      imageUrl: bgmi2,
      alt: 'Game 1',
    },
    {
      imageUrl: genshinImg2,
      alt: 'Game 2',
    },
    {
      imageUrl: codImg2,
      alt: 'Game 3',
    },
    {
        imageUrl: mlbbImg2,
        alt: 'Game 4',
      },
  ];
  
  export const TrendingGames = [
    {
      id:0
    },
    {
        id:1
    },
    {
        id:2
    },
    {
        id:3
    },
    {
        id:4
    },
    {
        id:5
    },
  ];

