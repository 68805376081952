import React from 'react'
import './bids.css'
import { AiFillHeart,AiOutlineHeart } from "react-icons/ai";
import bids1 from '../../assets/bids1.png'
import bids2 from '../../assets/bids2.png'
import bids3 from '../../assets/bids3.png'
import bids4 from '../../assets/bids4.png'
import bids5 from '../../assets/bids5.png'
import bids6 from '../../assets/bids6.png'
import bids7 from '../../assets/bids7.png'
import bids8 from '../../assets/bids8.png'
import { Link } from 'react-router-dom';
import itemData from '../../utils/itemData';
// const bidsData = [
//   {
//     imgSrc: bids1,
//     title: "Abstact Smoke Red",
    
//   },
//   {
//     imgSrc: bids2,
//     title: "Mountain Landscape",
    
//   },
 
//   // Add more objects for your other items
// ];



const Bids = ({ title, loadMore }) => {
  return (
    <div className='bids section__padding'>
      <div className="bids-container">
        <div className="bids-container-text">
          <h1>{title}</h1>
        </div>
          <div className="bids-container-card">
            {itemData.map((item, index) => (
              <div className="card-column" key={index}>
                <div className="bids-card">
                    <Link to={`/post/${item.id}`}>
                  <div className="bids-card-top">
                    <img src={item.imgSrc} alt="" />
                    {/* <div className = "zig-zag"></div> */}
                    <p className="bids-title">{item.title}</p>
                   
                  </div>
                  </Link>
                  <div className="bids-card-bottom">

                    {/* <p>{item.ethPrice} <span>ETH</span></p>
                    <p> <AiFillHeart /> {item.hearts}</p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
      </div>
      {loadMore && (
        <div className="load-more">
          <button>Load More</button>
        </div>
      )}
    </div>
  )
}

export default Bids
